import React from 'react';
import { useForm } from 'react-hook-form';
import Button from '../Button/Button';
import Input from '../Input/Input';
import { FormWrapper, FormErrorMessage } from './Form.styles';

const FormTemplate = ({
  title,
  description,
  buttonText,
  loading,
  background,
  error,
  success,
  destinationUrl,
  className,
  onSubmit,
  urlOnNewTab,
  ...props
}) => {
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, defaultValues },
  } = useForm({ defaultValues: { terms: false, ...(props.mock ? props : {}) } });
  const fields = watch();

  const enableSubmitButton = React.useMemo(
    () =>
      fields.name &&
      fields.name !== '' &&
      fields.email &&
      fields.email !== '' &&
      fields.terms &&
      fields.terms !== ''
    [fields],
  );

  return (
    <FormWrapper
      success={success && (!destinationUrl || urlOnNewTab)}
      background={background}
      className={className}
      inputError={errors.phone && errors.phone.message}
    >
      {title && <h1>{title}</h1>}
      {description && <p>{description}</p>}
      <div className="content-wrapper">
        <form
          name="form"
          onSubmit={onSubmit && handleSubmit((data) => onSubmit(data))}
          className={success && (!destinationUrl || urlOnNewTab) ? 'hide' : ''}
          autoComplete="on"
        >
          <div className="form-row">
            <div className="input-label">
              <Input
                autoComplete="on"
                placeholder="Nome"
                type="text"
                {...register('name')}
                id="name"
                required
              />
            </div>
            <div className="input-label">
              <Input
                placeholder="E-email"
                type="email"
                {...register('email')}
                id="email"
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="input-label">
              <Input
                placeholder="Nome da empresa (opcional)"
                {...register('company')}
                type="text"
                id="company"
              />
            </div>
          </div>
          <footer>
            <div className="checkbox-wrapper" aria-live="assertive">
              <label className="checkbox">
                <input
                  type='checkbox'
                  {...register("terms")}
                />{' '}
                <span className="checkmark" />
                Aceito os{' '}
              </label>
              <a href="https://www.appmasters.io/pt/termos-e-condicoes" target="_blank" rel="noreferrer">termos e condições</a>
            </div>
            <Button
              backgroundColor={background}
              disabled={loading || !enableSubmitButton}
              type="submit"
            >
              {/* {loading && <LoadingIcon />} */}
              {buttonText}
            </Button>
          </footer>
          {error === true && <FormErrorMessage id="error">Ocorreu um erro ao enviar o formulário. Tente novamente</FormErrorMessage>}
        </form>
      </div>
    </FormWrapper>
  );
};

export default FormTemplate;
