export const GA_TRACKING_ID = process.env.REACT_APP_GOOGLE_TRACKING_ID;


// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = (action) => {
  console.log("A - event", action);
  const value = process.env.REACT_APP_GTAG_EVENT_QUEST_QUOTE;
  if (!value || !GA_TRACKING_ID) return;

  // Mounting event object
  const params = {};
  switch (action) {
    case 'conversion': {
      params['send_to'] = value;
    }
  }

  // Sending to gtag
  const r = window.gtag('event', action, params);

  console.log("B - window.gtag", action, params);
  console.log("C - window.gtag result", r);
};
