export const HUBSPOT_TRACKING_TOKEN = 'hubspotutk';

export const submitHubSpotForm = async (
  formId,
  portalId,
  form,
  context,
) => {
  const fields = [];
  for (const attr in form) {
    fields.push({ name: attr, value: form[attr] });
  }

  const hubspotTrackingToken = document.cookie
    ?.split(`${HUBSPOT_TRACKING_TOKEN}=`)[1]
    ?.split(';')[0];

  return fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`, {
    method: 'post',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      fields,
      context: { ...context, ...(hubspotTrackingToken && { hutk: hubspotTrackingToken }) },
    }),
  });
};

export const submitHubspotContact = async ({ contact }) => {
  return fetch(`https://api.hubspot.com/crm/v3/properties/contacts`, {
    method: 'post',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Authorization': `Bearer ${process.env.REACT_APP_HUBSPOT_CONTACT_TOKEN}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      properties: contact
    })
  })
}