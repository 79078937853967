import React, { Component } from "react";
import reactReferer from "react-referer";

import Presenter from "../components/messagerPresenter";
import getQuestions from "../dataQuestions";

import logo from "../assets/images/white_logo.svg";

import { init } from "emailjs-com";
import SectionTextButtons from "../components/SectionTextButtons/SectionTextButtons";
import Sidebar from "../components/Sidebar/Sidebar";
import Questions from "../components/Questions/Questions";
import { HomeWrapper, StartContainer } from "./app.styles";
import FormSection from "../components/FormSection/FormSection";
import Button from "../components/Button/Button";
import { smallAppMockAnswers, bigAppMockAnswers } from "../utils/mock";


console.log(process.env);

init(process.env.REACT_APP_EMAIL_USER_ID);

class App extends Component {
  constructor() {
    super();

    this.handleResume = this.handleResume.bind(this);
    this.checkFinally = this.checkFinally.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleCountQuestion = this.handleCountQuestion.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.getParameterByName = this.getParameterByName.bind(this);
    this.updateUser = this.updateUser.bind(this);

    this.state = {
      start: false,
      countQuestion: 0,
      questions: getQuestions('aplicativo'),
      currentQuestion: 0,
      questionsWithAnswers: getQuestions('aplicativo'),
      resume: [],
      name: "",
      email: "",
      company: "",
      alert: true,
      referer: reactReferer.referer(),
      source: "",
      medium: "",
      campaign: "",
      type: 'aplicativo',
      mock: {},
    };
  }

  componentDidMount() {
    window.fbq("track", "ViewContent");

    const type = this.getParameterByName("t");

    this.setState({
      source: this.getParameterByName("utm_source"),
      medium: this.getParameterByName("utm_medium"),
      campaign: this.getParameterByName("utm_campaign"),
      type: type === 'MVP' || type === 'SaaS' ? type : 'app',
      questions: getQuestions(type === 'app' || !type ? 'aplicativo' : type),
      questionsWithAnswers: getQuestions(type === 'app' || !type ? 'aplicativo' : type),
    });

    let interval = null;
    interval = setInterval(hideHubspotChat, 200);

    function hideHubspotChat() {
      const hs = document.getElementById('hubspot-messages-iframe-container');
      
      if (hs) {
        const style = hs.getAttribute('style') || '';
        hs.setAttribute('style', `${style} display: none !important;`);
        clearInterval(interval);
      }
    }

    // if(window.location.search.slice(1, 6)) {
    //     this.setState({
    //         source: 'google',
    //         medium: 'cpc',
    //         campaign: 'adwords'
    //     });
    // } else {
    //     this.setState({
    //         source: this.getParameterByName('utm_source'),
    //         medium: this.getParameterByName('utm_medium'),
    //         campaign: this.getParameterByName('utm_campaign')
    //     });
    // }
  }

  handleResume(values) {
    this.setState({
      resume: [...this.state.resume, values],
    });
  }

  checkFinally() {
    const { questions, countQuestion } = this.state;
    return questions.length <= countQuestion;
  }

  handleRefresh() {
    this.setState({
      countQuestion: 0,
      resume: [],
    });
  }

  handleCountQuestion(value) {
    this.setState({
      countQuestion: this.state.countQuestion + value,
    });
  }

  handleChange(e, key) {
    this.setState({
      [key]: e.target.value,
    });
  }

  handleCheck(e, key) {
    this.setState({
      [key]: e.target.checked,
    });
  }

  getParameterByName(name) {
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(window.location.search || this.state.referer);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  updateUser(data) {
    this.setState({
      name: data.name,
      email: data.email,
      company: data.company,
    })
  }

  render() {
    const { countQuestion, questions, resume, start } = this.state;
    if (
      window.location.pathname &&
      window.location.pathname.indexOf("whatsapp") > -1
    ) {
      return <Presenter />;
    }

    const showForm = this.state.currentQuestion === questions.length;

    return (
      <HomeWrapper>
        <Sidebar questions={this.state.questionsWithAnswers} hideOptions={!start} />
        <div style={{ height: '100%' }}>
          {!showForm && (!start ? (
            <StartContainer>
              <SectionTextButtons
                title="Quanto custa desenvolver um aplicativo?"
                backgroundColor="lightGradient"
                text={`Que tal saber o investimento necessário e prazo para o desenvolvimento de seu ${this.state.type === 'app' ? 'aplicativo' : this.state.type}?`}
                textAlign='center'
                buttons={[
                  {
                    title: 'Começar agora',
                    onClick: () => {
                      this.setState({ start: true });
                    }
                  }
                ]}
                buttonsAlignment="center"
              />
              {process.env.NODE_ENV === "development" && (
                <>
                  <Button
                    className="btn"
                    style={{ width: '100%', maxWidth: 400, marginTop: 20 }}
                    onClick={() => {
                      this.setState({
                        currentQuestion: questions.length,
                        questionsWithAnswers: smallAppMockAnswers,
                        start: true,
                        mock: {
                          name: "Tester",
                          email: "test@test.com",
                          company: "App Masters",
                          mock: true,
                          terms: true,
                        }

                      });
                    }}
                  >
                    [dev] Preencher com app mínimo
                  </Button>
                  <Button
                    className="btn"
                    style={{ width: '100%', maxWidth: 400, marginTop: 20 }}
                    onClick={() => {
                      this.setState({
                        currentQuestion: questions.length,
                        questionsWithAnswers: bigAppMockAnswers,
                        start: true,
                        mock: {
                          name: "Tester",
                          email: "test@test.com",
                          company: "App Masters",
                          mock: true,
                          terms: true,
                        }

                      });
                    }}
                  >
                    [dev] Preencher com app completo
                  </Button>
                </>
              )}
            </StartContainer>
          ) : (
            <Questions
              questions={this.state.questionsWithAnswers}
              currentQuestion={this.state.currentQuestion}
              setCurrentQuestion={(index) => this.setState({ currentQuestion: index })}
              setSelectedAnswer={(answer) =>
                this.setState({
                  questionsWithAnswers: this.state.questionsWithAnswers.map((q, i) =>
                    i === this.state.currentQuestion ? { ...q, selectedAnswer: answer } : q
                  )
                }
                )}
            />
          ))}
          {showForm && (
            <FormSection
              {...this.state}
              {...this.state.mock || {}} // Fake state
              updateUser={this.updateUser}
              state={{
                resume: this.state.questionsWithAnswers.map((q) => ({
                  category: q.title,
                  option: q.selectedAnswer,
                  questionId: q.id
                }))
              }}
              resetToFirstView={() => {
                this.setState({
                  questionsWithAnswers: this.state.questionsWithAnswers.map((q) => ({
                    ...q,
                    selectedAnswer: null
                  })),
                  currentQuestion: 0,
                })
              }}
            />
          )}
        </div>
        {/* Display footer only on mobile */}
        <footer>
          <a href="https://www.appmasters.io/pt?utm_source=quanto_custa" target="_blank" rel="noreferrer">
            <img className="white-logo" src={logo} alt="Logo da App Masters" />
          </a>
        </footer>
      </HomeWrapper>
    );
  }
}

export default App;
