import * as colors from './colors';

const theme = {
  name: 'Default',
  colors,
  border: {
    borderRadius: '10px',
    borderDark: '1px solid' + colors.newBorder,
  },
};

export default theme;
