import styled, { css } from "styled-components";
import media from "../../styles/media";

export const Wrapper = styled.div`
  ${({ theme }) => css`
    height: 100vh;
    min-width: 430px;
    position: relative;

    header {
      width: 100%;
      padding: 40px;
      margin-top: 0px;
      background-color: #182750;

      h1 {
        color: ${theme.colors.white};
        font-size: 33px;
      }
    }

    & > div {
      display: flex;
      flex-direction: column;
      padding: 40px;
      height: calc(100vh - (160px + 80px));

      ul {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0px;
        margin: 0px;

        li {
          display: flex;
          align-items: center;
          gap: 14px;
          opacity: 0.35;

          &.answered {
            opacity: 1;

            div.question-index {
              color: ${theme.colors.boldText};
            }
          }

          :last-of-type {
            /* Hide vertical line for the last child */
            div.question-index::after { 
              display: none;
            }
          }

          div.question-index {
            width: 58px;
            height: 58px;
            border-radius: 100%;
            background-color: #E1EDFF;
            font-size: 23px;
            font-weight: 700;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #7182AB;
            position: relative;

            ::after {
              content: '';
              position: absolute;
              width: 2px;
              height: 20px;
              background-color: #E1EDFF;
              bottom: -16px;
            }
          }

          p {
            display: flex;
            flex-direction: column;
            gap: 6px;
            justify-content: flex-start;
            color: ${theme.colors.white};
            margin: 0px;

            strong {
              font-size: 16px;
              font-weight: 700;
            }

            span {
              color: inherit;
              font-size: 14px;
            }
          }
        }
      }
    }

    footer {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #182750;
      height: 80px;
    }

    ${media('mediumPlus')} {
      background-color: ${theme.colors.darkBlue};
    }

    ${media('mediumPlus', true)} {
      height: unset;
      min-width: 0px;

      header {
        padding: 0px 20px;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;

        h1 {
          font-size: 28px;
        }
      }
      
      & > div {
        overflow-x: auto;
        padding: 20px 0px 20px 20px;

        ul {
          display: flex;
          flex-direction: row;
          width: 100%;
          flex: 1;
          white-space: nowrap;
          padding-right: 10px;
          gap: 20px;

          li {
            opacity: 1;
            gap: 6px;

            :last-of-type {
              padding-right: 20px;
            }

            div.question-index {
              color: ${theme.colors.boldText};
              min-width: 36px;
              min-height: 36px;
              max-width: 36px;
              max-height: 36px;
              font-size: 14px;
              font-weight: 700;

              ::after {
                display: none;
              }
            }

            p {
              color: ${theme.colors.darkText};

              strong {}
              span {}
            }
          }
        }
      }
    }
  `};
`;