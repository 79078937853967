import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  *, *::after, *::before {
    box-sizing: border-box;
    font-family: 'filson-soft', sans-serif;
    &:not(:focus-visible){
      outline: none;
    }
  }

  
  body, html {
    margin: 0;
    padding: 0;
    color: ${(props) => props.theme.colors.darkTextPale};
    overflow-x: hidden !important;
    background: ${(props) => props.theme.colors.lightGradient};
  }

  body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  ::-webkit-scrollbar{
    display: none;
  }

  .clamp-lines__button {
    padding: 0;
    color: ${(props) => props.theme.colors.blue};
    border: 0px solid transparent;
    background: transparent;
    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  #___gatsby, #gatsby-focus-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  p, h1, h2, h3, h4, h5, span {
    font-family: "filson-soft", sans-serif;
    color: ${(props) => props.theme.colors.darkTextPale};   
  }
  p,a{
    font-weight: 400;
    
  }

  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      opacity: .9;
    }
  }

  .sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
    -webkit-clip-path: inset(50%) !important;
      clip-path: inset(50%) !important;  /* 2 */
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;            /* 3 */
  }

  .sr-only-focusable:focus,
  .sr-only-focusable:active {
    clip: auto !important;
    -webkit-clip-path: none !important;
      clip-path: none !important;
    height: auto !important;
    margin: auto !important;
    overflow: visible !important;
    width: auto !important;
    white-space: normal !important;
  }


  ::placeholder {
    color: ${(props) => props.theme.colors.darkSectionBg};
    opacity: 1;
    font-weight: 400;
  }

  :-ms-input-placeholder {
    color: ${(props) => props.theme.colors.darkSectionBg} !important;
  }

  ::-ms-input-placeholder { 
    color: ${(props) => props.theme.colors.darkSectionBg} !important;
  }

  .grecaptcha-badge { 
    visibility: hidden;
  } 
`;

export default GlobalStyles;
