import React from 'react';
import { Wrapper } from './TitleWithDescription.styles';

const TextWithDescription = ({ title, description, titleVariant }) => {
  return (
    <Wrapper>
      {title && (
        titleVariant === 1 ? <h2>{title}</h2> : <h3>{title}</h3>
      )}
      {description && (
        <p>
        {description}
      </p>
      )}
    </Wrapper>
  )
};

export default TextWithDescription;