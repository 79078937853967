import styled from "styled-components";
import media from "../../styles/media";

export const Wrapper = styled.div`
  width: 100%;
  margin: auto;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;

  div.img-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 500px;
      border-radius: 20px;
    }
  }

  ${(media('mediumPlus', true))} {
    height: 100%;
  }
`

export const ResultWrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

