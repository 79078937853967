import styled from 'styled-components';
import media from '../../styles/media';



export const Wrapper = styled.div`
    @keyframes shake {
        0% { transform: translate(1px, 1px) rotate(0deg); }
        3% { transform: translate(-1px, -2px) rotate(-1deg); }
        5% { transform: translate(-3px, 0px) rotate(1deg); }
        8% { transform: translate(3px, 2px) rotate(0deg); }
        10% { transform: translate(1px, -1px) rotate(1deg); }
        13% { transform: translate(-1px, 2px) rotate(-1deg); }
        15% { transform: translate(-3px, 1px) rotate(0deg); }
        18% { transform: translate(3px, 1px) rotate(-1deg); }
        20% { transform: translate(-1px, -1px) rotate(1deg); }
        23% { transform: translate(1px, 2px) rotate(0deg); }
        26% { transform: translate(1px, -2px) rotate(-1deg); }
        28% { transform: translate(0px, 0px) rotate(0deg); }
    }
    
    .shake {
        animation: shake 2s infinite;
        animation-delay: 2s;
    }

    padding: 5px;
    height: 100%;  

    ${media('medium')} {
        display: flex;
        align-items: center;
    }

    .section-title {
        margin-bottom: 0;
    }
    p {
        margin-top: 4px;
    }

    .total-resume {
        display: flex;
        justify-content: space-around;
        width: 100%;
        gap: 10px;
        flex-direction: row;

        ${media('medium', true)} {
            align-items: center;
            flex-direction: column; 
        }

        

        .box-total {
            width: 100%;
            text-align: center;
            padding: 20px 10px;
            font-weight: 600;            
           
            background-color: white;
            border-radius: 10px;

            .sum-price {
                font-size: 2.4em;
                color: ${props => props.theme.colors.lightBlue};
                display: flex;
                justify-content: center;
                flex-direction: row;  
                align-items: flex-end;
                gap: 4px;
            }

            small {
                font-size: 22px;  
                margin-bottom: 5px;
                color: ${props => props.theme.colors.lightBlue};
            }
        }
    }

    .radio-group {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: row;
        gap: 5px;
        margin-bottom: 30px;
        flex-wrap: wrap;

        input[type="radio"] {
            -ms-transform: scale(2); /* IE 9 */
            -webkit-transform: scale(2); /* Chrome, Safari, Opera */
            transform: scale(2);
            margin: 0px;
        }      

        label {
            display: inline-block;
            cursor: pointer;
            flex: 1;
            border-radius: 10px;
            padding: 20px;
            background-color: white;
            display: flex;
            gap: 20px;
            align-items: center;
        }      


        .label-title {
            font-size: 16px;
            font-weight: 600;
        }
        .label-detail {
            font-size: 12px;
            margin: 0;
            margin-top: 10px;
        }

        ${media('extraSmall', true)} { 
            label {
                padding: 10px;
                gap: 7px;
            }

            input[type="radio"] {
                -ms-transform: scale(1.3); /* IE 9 */
                -webkit-transform: scale(1.3); /* Chrome, Safari, Opera */
                transform: scale(1.3);
            }  
        }
    }       

    

    .button-wrapper {
        padding: 10px 0;
        display: flex;
        justify-content: center;
        button {
            width: 100%;
        }
    }

    .info-wrapper {
        padding: 10px;
        background-color: white;
        border-radius: 10px;
        font-weight: 200;
    }

    .negative-message {
        text-align: center;
        padding: 30px;
        background-color: white;
        margin-bottom: 20px;
        border-radius: 10px;
        h5 {
            margin: 0;
            font-size: 20px;
            color: ${props => props.theme.colors.darkBlue};
        }
        p {
            margin: 0;
            color: ${props => props.theme.colors.darkBlue};
        }
    }
    
    label.phone-label {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-top: 10px;
        margin-bottom: 10px;

        span {
            color: ${(props) => props.theme.colors.darkBlue};
            font-weight: 600;
        }

        & + button {
            margin-bottom: 30px;
            width: 100%;
        }
    }
`;
