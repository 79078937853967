import styled, { css } from "styled-components";

export const Wrapper = styled.div`

${({ theme }) => css`
    h2, h3, p {
      color: ${theme.colors.darkText};
      font-weight: 425px;
      margin-top: 0px;
    }

    h2 {
      font-size: 33px;
      font-weight: 600;
      margin-bottom: 4px;
    }

    h3 {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 4px;
    }

    p {
      margin-top: 7px;
      font-size: 16px;
    }
  `}
`;