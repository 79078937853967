import React from 'react';
import FormTemplate from './Form.template';

const formURL = process.env.REACT_APP_FORM_URL;


function Form(props) {
  const [error, setError] = React.useState(false);   
  const onSubmit = (data) => {
    props.onFinish(data);
  };

  return (
    <FormTemplate
      {...props}
      onSubmit={onSubmit}
      error={error}
      locale="pt"
      setError={(value) => setError(value)}
    />
  );
}

export default Form;
