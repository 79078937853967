import styled from "styled-components";
import media from "../styles/media";

export const HomeWrapper = styled.div`
  display: flex;
  width: 100vw;
  background: ${(props) => props.theme.colors.lightGradient};

  img.white-logo {
    cursor: pointer;
    filter: grayscale(1) brightness(0) invert(1);
    width: 150px;
    transition: all 0.2;

    :hover {
      transform: scale(1.01);
    }
  }

  & > div:last-of-type {
    margin: auto;
    margin-left: 0px;
    padding: 0px 150px;
    width: 100%;
    max-width: 1200px;
  }

  & > footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${(props) => props.theme.colors.darkBlue};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 70px;

    p {
      color: white;
      font-size: 16px;
      font-weight: 700;
    }

    ${media('mediumPlus')} {
      display: none;
    }
  }

  ${media('extraLarge', true)} {
    & > div:last-of-type {
      padding: 0px 30px;
    }
  }

  ${media('mediumPlus', true)} {
    flex-direction: column;
    height: 100vh;

    & > div:last-of-type {
      padding: 0px 20px;
      height: calc(100vh - (110px + 130px)) !important;
      margin-top: 28px;
      overflow-y: auto;
    }
  }
`;

export const StartContainer = styled.div`
  height: calc(100vh - 280px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  * {
    text-align: center !important;
  }
`