const getQuestions = (type) => [{
    id: '006',
    title: 'Login',
    subtitle: `Como será a autenticação no seu ${type}?`,
    options: [
        {
            id: '019',
            label: 'Não é necessário realizar login',
            time: 0
        },
        {
            id: '020',
            label: 'Autenticar com endereço de email',
            time: 40
        },
        {
            id: '020',
            label: 'Acessar usando redes sociais ou email',
            time: 40
        }
    ]
},
    {
        id: '005',
        title: 'Cobranças',
        subtitle: `Haverá cobranças no ${type}?`,
        options: [
            {
                id: '015',
                label: `${type} sem retorno financeiro`,
                time: 0
            },
            {
                id: '017',
                label: `${type} deve ser comprado na loja`,
                time: 30
            },
            {
                id: '017',
                label: 'O usuário fará uma assinatura',
                time: 30
            },
            {
                id: '018',
                label: `Compras dentro do ${type}`,
                time: 40,
                priceEffect: 2
            }
        ]
    },
    
    {
        id: '007',
        title: 'Integrar com sistema externo',
        subtitle: 'O projeto precisa lidar com API ou serviços de terceiros?',
        options: [
            {
                id: '021',
                label: 'Não',
                time: 0
            },
            {
                id: '022',
                label: 'Realizar autenticação via API',
                time: 30,
                priceEffect: 2
            },
            {
                id: '023',
                label: 'Integrar com API ou serviços',
                time: 80,
                priceEffect: 3
            }
        ]
    },
    {
        id: '010',
        title: 'Notificações',
        subtitle: 'Os usuários receberão notificações?',
        options: [
            {
                id: '030',
                label: 'Não é preciso enviar notificações',
                time: 0
            },
            {
                id: '031',
                label: 'Receberão notificações em algumas situações',
                time: 30
            }
        ]
    },
    {
        id: '012',
        title: 'Relatórios',
        subtitle: `O ${type} terá relatórios?`,
        options: [
            {
                id: '037',
                label: 'Sem necessidade de relatórios',
                time: 0
            },
            {
                id: '038',
                label: 'Apenas relatórios simples',
                time: 30,
                priceEffect: 2
            },
            {
                id: '039',
                label: 'Relatórios com interativos ou com gráficos',
                time: 90,
                priceEffect: 3
            }
        ]
    },
    {
        id: '013',
        title: 'Dashboard',
        subtitle: 'Apresentar um dashboard rico para o usuário',
        options: [
            {
                id: '038',
                label: 'Não apresentar nada especial',
                time: 0
            },
            {
                id: '039',
                label: 'Apresentar alguns números e informações',
                time: 30,
                priceEffect: 2
            },
            {
                id: '040',
                label: 'Incluir gráficos e estatísticas',
                time: 90,
                priceEffect: 3
            }
        ]
    }
];

export default getQuestions;