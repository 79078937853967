import styled, { css } from 'styled-components';

export const InputStyle = css`
  font-size: 1em;
  font-weight: 400;
  height: 42px;
  transition: border-color 0.2s ease-in-out 0s;
  width: 100%;
  border: 2px solid ${(props) => props.theme.colors.newBorder};
  padding: 8px;
  border-radius: 6px;

  &::placeholder {
    color: ${(props) => props.theme.colors.newBorder};
    opacity: 1;
    font-weight: 400;
  }

  &:hover {
    border-color: ${(props) => props.theme.colors.blue};
  }
  &:focus {
    border-color: ${(props) => props.theme.colors.blue};
  }
`;

const Input = styled.input`
  ${InputStyle}
`;

export default Input;
