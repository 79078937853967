import styled from 'styled-components';

export const CallToActionWrapper = styled.section`
  width: 100%;
  text-align: ${(props) => props.textAlign};
`;

export const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: ${(props) => props.buttonsAlignment};
  justify-content: ${(props) => props.buttonsAlignment};
  width: 100%;

  margin-top: 54px;

  div {
    display: flex;
    gap: 16px;
    text-decoration: none;
  }

  a {
    text-decoration: none;
  }
`;
