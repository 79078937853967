import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import qs from "qs";
import axios from "axios";
import TextWithDescription from "./TitleWithDescription/TitleWithDescription";
import SectionTextButtons from "./SectionTextButtons/SectionTextButtons";
import styled from "styled-components";

const Wrapper = styled.div`
    p {
        text-align: center;
    }
`   

const formURL = process.env.REACT_APP_FORM_URL;

class ConfirmRequest extends Component {
    constructor() {
        super();

        this.handleChange = this.handleChange.bind(this);
        this.handleFinish = this.handleFinish.bind(this);

        this.state = {
            open: false,
            phone: "",
            message: "",
            talk: "WhatsApp",
        };
    }

    componentDidMount() {
        window.fbq("track", "AddToWishlist");
    }

    handleChange(e, key) {
        if (key === "phone") {
            const x = e.target.value
                .replace(/\D/g, "")
                .match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
            e.target.value = !x[2]
                ? x[1]
                : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
        }

        this.setState({
            [key]: e.target.value,
        });
    }

    openDialog() {
        this.setState({ open: true });
    }

    handleFinish() {
        this.setState({ open: false });
        window.location.reload();
    }

    sendData() {
        const {
            name,
            email,
            alert,
            company,
            referer,
            source,
            medium,
            campaign,
        } = this.props.state;
        const { phone, message, talk } = this.state;
        const { price, deadline, address, interest, handleResume } = this.props;

        const data = {
            name: name,
            email: email,
            alert: alert,
            talk: talk,
            company: company,
            interest: interest,
            ip: address.ip,
            city: address.city,
            state: address.stateProv,
            phone: phone,
            referer: referer,
            source: source,
            medium: medium,
            campaign: campaign,
            message: message,
            price: price,
            deadline: deadline,
            itens: handleResume().split(`<br/>`).join("\n"),
        };

        axios
            .post(formURL, qs.stringify(data), {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    render() {
        const { open, phone, message, talk } = this.state;
        const {
            name,
            email,
            company,
            alert,
            resume,
            referer,
            source,
            medium,
            campaign,
        } = this.props.state;
        const { price, deadline, address, lookingFor } = this.props;

        return (
            <Wrapper>
                <div className="animated slideInDown" style={{ textAlign: 'center'}}>
                    {['yes', 'partially'].includes(lookingFor) ? (
                        <SectionTextButtons 
                            text="Entraremos em contato por telefone ou email em breve para entendermos melhor suas necessidades e lhe oferecer a melhor solução &#x1F44D;" 
                            buttons={[
                                {
                                    title: 'Visitar site da App Masters',
                                    onClick: () => {
                                        window.open('https://www.appmasters.io/pt', '_blank')
                                    }
                                }
                            ]}
                        />
                    ) : (
                        <>
                            <SectionTextButtons 
                                text="Ficamos tristes que não alcançamos suas expectativas de valores. O investimento para desenvolver um projeto de software com uma boa qualidade pode realmente ser alto &#x1F622;" 
                                buttons={[
                                    {
                                        title: 'Começar novamente',
                                        onClick: () => {
                                            this.props.resetToFirstView();
                                        }
                                    }
                                ]}
                            />
                        </>
                    )}
                    
                    {/* <p className="text-intro">
                        Confirme seus dados abaixo para concluir. Entraremos em contato para
                        entendermos melhor sua demanda e chegarmos a um modelo adequado de
                        projeto, tanto em prazo quanto em custo, mas principalmente, nas
                        funcionalidades.
                    </p>
                    <div id="form" className="form">
                        <div className="ipt-group">
                            <label>Nome</label>
                            <input
                                className="ipt"
                                id="name"
                                name="name"
                                type="text"
                                value={name}
                                disabled
                            />
                        </div>
                        <div className="ipt-group">
                            <label>E-mail</label>
                            <input
                                className="ipt"
                                id="email"
                                name="email"
                                type="text"
                                value={email}
                                disabled
                            />
                        </div>
                        {company ? (
                            <div className="ipt-group">
                                <label>Nome da empresa</label>
                                <input
                                    className="ipt"
                                    id="company"
                                    name="company"
                                    type="text"
                                    value={company}
                                    disabled
                                />
                            </div>
                        ) : null}
                        <div className="ipt-group">
                            <label htmlFor="phone">Telefone</label>
                            <input
                                className="ipt"
                                id="phone"
                                name="phone"
                                type="text"
                                value={phone}
                                placeholder="(xx) xxxxx-xxxx"
                                onChange={(e) => this.handleChange(e, "phone")}
                            />
                        </div>
                        <span>Fale comigo por*</span>
                        <div className="check-options">
                            <div className="check-group">
                                <label htmlFor="checkWhats">WhatsApp</label>
                                <input
                                    className="check"
                                    id="checkWhats"
                                    name="talk"
                                    value="WhatsApp"
                                    type="radio"
                                    checked={talk === "WhatsApp"}
                                    onChange={(e) => this.handleChange(e, "talk")}
                                    required
                                />
                            </div>
                            <div className="check-group">
                                <label htmlFor="checkEmail">E-mail</label>
                                <input
                                    className="check"
                                    id="checkEmail"
                                    name="talk"
                                    value="E-mail"
                                    type="radio"
                                    checked={talk === "E-mail"}
                                    onChange={(e) => this.handleChange(e, "talk")}
                                    required
                                />
                            </div>
                            <div className="check-group">
                                <label htmlFor="checkPhone">Telefone</label>
                                <input
                                    className="check"
                                    id="checkPhone"
                                    name="talk"
                                    value="Telefone"
                                    type="radio"
                                    checked={talk === "Telefone"}
                                    onChange={(e) => this.handleChange(e, "talk")}
                                    required
                                />
                            </div>
                        </div>
                        <div className="ipt-group">
                            <label htmlFor="message">Detalhes adicionais</label>
                            <textarea
                                className="ipt textarea"
                                id="message"
                                name="message"
                                value={message}
                                onChange={(e) => this.handleChange(e, "message")}
                            />
                        </div>
                        <input
                            className={`btn btn-confirm ${!(phone.length >= 14 && talk) ? "btn-disabled" : ""
                                }`}
                            type="submit"
                            value="Concluir"
                            onClick={() => {
                                this.sendData();
                                sendEmailUs({
                                    name,
                                    email,
                                    alert,
                                    company,
                                    referer,
                                    source,
                                    medium,
                                    campaign,
                                    resume,
                                    price,
                                    deadline,
                                    proposalRequested: "Sim",
                                    city: address.city,
                                    stateProv: address.stateProv,
                                    phone,
                                    talk,
                                    message,
                                    sumHour: Object.keys(resume).reduce((prevVal, index) => {
                                        return prevVal + resume[index].option.time;
                                    }, 130),
                                });
                                this.openDialog();
                            }}
                            disabled={!(phone.length >= 14 && talk)}
                        />
                    </div> */}
                </div>

                {open ? (
                    <div className="dialog animated slideInDown">
                        <h2>Sucesso</h2>
                        <p>Obrigado por chegar até aqui. Entraremos em contato em breve.</p>
                        <button onClick={() => this.handleFinish()}>finalizar</button>
                    </div>
                ) : null}
            </Wrapper>
        );
    }
}

export default withRouter(ConfirmRequest);
