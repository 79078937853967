export const darkBlue = '#1F2F59';
export const lightBlue = '#1D42CF';
export const darkGradient = 'linear-gradient(78.66deg, #1D42CF -22.45%, #3888FC 105.12%)';
export const lightGrayBlue = '#E1EDFF';

export const lightGradient =
  'radial-gradient(141.77% 218.28% at -42.79% 9.15%, #dcebff 0%, #ffffff 100%)';
export const white = '#fafafa';

export const darkText = '#1F2F59';
export const lightText = '#C0DAFF';
export const darkTextPale = '#566384';
export const mutedText = '#a0a6ca';

export const newBorder = '#bcc3d7';
export const cardBlue = '#AECFFF';
export const lightCardBlue = `${cardBlue}66`;
export const boldText = '#1E41A0';