import styled, { css } from 'styled-components';

const Button = styled.button`
  position: relative;
  padding: 15px 20px;
  gap: 10px;
  border: 0;
  font-weight: 400;
  font-size: 1em;
  transition: all 0.2s;
  transform-origin: bottom;
  border-radius: 8px;
  cursor: pointer;
  border: 0;
  color: white;
  z-index: 1;

  ${(props) => props.variant === 'ghost' ? css`
    background-color: transparent !important;
    color: ${(props) => props.theme.colors.boldText};

    :hover {
      outline: 1px solid ${(props) => props.theme.colors.boldText} !important;
    }
  ` : css`
      background: linear-gradient(78.66deg, #1d42cf -22.45%, #3888fc 105.12%);

      :hover {
        filter: saturate(1.3);
      }
  `}

  

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export default Button;
