import React from 'react';
import { Wrapper } from './Sidebar.styles';
import { useMedia } from 'react-use';
import logo from "../../assets/images/white_logo.svg";

const Sidebar = ({ questions, hideOptions }) => {
  const isMobile = useMedia('(max-width: 940px)');

  return (
    <Wrapper>
      <header>
        <h1>
          Quanto custa um App
        </h1>
      </header>
      {!isMobile && (
      <div>
        {!hideOptions && ( // TODO: maybe hide the entire sidebar when not on mobile ?
          <ul>
            {questions.map((q, i) => (
              <li key={q.title} className={q.selectedAnswer ? 'answered' : ''}>
                <div className="question-index">{i + 1}</div>
                <p>
                  <strong>{q.title}</strong>
                  {q.selectedAnswer && (
                    <span>R: {`${q.selectedAnswer.label[0].toUpperCase()}${q.selectedAnswer.label.slice(1).toLowerCase()}`}</span>
                  )}
                </p>
              </li>
            ))}
          </ul>
          )}
        </div>
      )}
      {!isMobile && (
        <footer>
          <a href="https://www.appmasters.io/pt?utm_source=quanto_custa" target="_blank" rel="noreferrer">
            <img className="white-logo" src={logo} alt="Logo da App Masters" />
          </a>
        </footer>
      )}
    </Wrapper>
  )
};

export default Sidebar;