import React, { Component } from 'react';
import logoMasters from '../assets/images/logo_appmasters.png';

const modes = [
    {
        mode: 'Tiago',
        textToSend: `Olá amigo!
        
Aqui é o Tiago Gouvêa, da *App Masters*, empresa focada no desenvolvimento de aplicativos e sistemas web. Gostaria de compartilhar contigo três links rápidos. 🎯

🔗 O site da App Masters:
https://appmasters.io

📱 Quanto custa meu aplicativo:
https://quantocustaumapp.appmasters.io

🙇‍♂ Meu contato pra você já salvar na sua agenda:
http://webcard.tiagogouvea.com.br/TiagoGouveaVcard.vcf`
    },
    {
        mode: 'Baraky',
        textToSend: `Olá!
        
Aqui é o Baraky, da *App Masters*, empresa focada no desenvolvimento de aplicativos e sistemas web. Gostaria de compartilhar contigo dois links rápidos. 🎯

🔗 O site da App Masters:
https://appmasters.io

📱 Quanto custa meu aplicativo:
https://quantocustaumapp.appmasters.io`
    },
    {
        mode: 'Bruno',
        textToSend: `Olá!
        
Aqui é o Bruno, da *App Masters*, empresa focada no desenvolvimento de aplicativos e sistemas web. Gostaria de compartilhar contigo dois links rápidos. 🎯

🔗 O site da App Masters:
https://appmasters.io

📱 Quanto custa meu aplicativo:
https://quantocustaumapp.appmasters.io`
    }
];

class Presenter extends Component {
    constructor() {
        super();
        
        this.state = {
            mode: null,
            textToSend: '',
            phone: '32'
        };
    }
    
    setMode(mode) {
        this.setState(mode);
    }
    
    handleChange(e, key) {
        this.setState({
            [key]: e.target.value
        });
    }
    
    sendWhatsapp() {
        let message = this.state.textToSend
            .replace(/\n/g, '<br>')
            .replace(/\t/g, '')
            .replace(/\s+/g, ' ')
            .replace(/<br>/g, '\n');
        window.open(`https://wa.me/55${this.state.phone}?text=${encodeURI(message)}`, '_blank');
    }
    
    nl2br(str) {
        if (typeof str === 'number') {
            return str;
        } else if (typeof str !== 'string') {
            return str;
        } else if (str === null || str.trim() === '' || str === undefined) {
            return str;
        }
        
        var newlineRegex = /(\r\n|\n\r|\r|\n)/g;
        
        return str.split(newlineRegex).map(function (line, index) {
            if (line.match(newlineRegex)) {
                return React.createElement('br', {key: index});
            } else {
                return line;
            }
        });
    }
    
    render() {
        return (
            <div className='container'>
                <div className='home'>
                    <img className='logo-masters' src={logoMasters} alt='App Masters' />
                    <h1 className='title'>Enviar mensagem</h1>
                    {this.state.mode === null ?
                        <div>
                            {modes.map(item => {
                                return (
                                    <div
                                        key={item.mode}
                                        style={{
                                            width: 400,
                                            maxWidth: 400,
                                            borderColor: '#C3C3C3',
                                            borderWidth: 1,
                                            borderStyle: 'solid',
                                            marginBottom: 10,
                                            padding: 10
                                        }}
                                        onClick={() => this.setMode(item)}>
                                        <b>{item.mode}</b>
                                    </div>
                                );
                            })}
                        </div>
                        : <div>
                            <div style={{
                                padding: 10,
                                marginBottom: 10,
                                backgroundColor: '#FFF',
                                borderColor: '#C3C3C3',
                                borderWidth: 1,
                                borderStyle: 'solid'
                            }}>
                                <p style={{textAlign: 'left'}}>{this.nl2br(this.state.textToSend)}</p>
                            </div>
                            <div className='ipt-group'>
                                <input
                                    className='ipt'
                                    id='phone'
                                    name='phone'
                                    type='text'
                                    style={{width: '100%', maxHeight: 50, padding: 10}}
                                    value={this.state.phone}
                                    placeholder='(xx) xxxxx-xxxx'
                                    onChange={(e) => this.handleChange(e, 'phone')}
                                />
                            </div>
                            <button
                                className='btn'
                                style={{width: 400, maxWidth: 400}}
                                onClick={() => this.sendWhatsapp()}>
                                Enviar mensagem
                            </button>
                        </div>
                    }
                
                </div>
            
            </div>
        );
    }
}

export default Presenter;
