import React from 'react';
import SectionTextButtons from '../SectionTextButtons/SectionTextButtons';
import { Wrapper, ResultWrapper } from './FormSection.styles';
import gif from './calculating-funny.gif';
import Form from '../Form/Form';
import FinishResume from '../FinishResume/FinishResume';
import axios from 'axios';
import Rollbar from 'rollbar';

const rollbar = new Rollbar({
  environment: process.env.NODE_ENV,
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
})

function FormSection(props) {
  const [pageIndex, setPageIndex] = React.useState(1);
  const [sendToHubspot, setSendToHubspost] = React.useState(false);

  const loadImage = (path) => new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(path);
    img.onerror = () => reject();

    img.src = path;
  });

  React.useEffect(() => {
    loadImage(gif);
  }, []);

  return (
    <>
      {pageIndex !== 4 && <Wrapper>
        {pageIndex === 1 && (
          <SectionTextButtons
            className='animated slideInRight'
            title="Ótimo!"
            text="Vamos calcular o investimento base deste projeto, e você poderá simular priorizando prazo, preço ou qualidade."
            buttonsAlignment='center'
            textAlign='center'
            buttons={
              [
                {
                  title: 'Avançar',
                  onClick: () => {
                    setPageIndex(pageIndex === 2 ? 3 : 2);
                    setTimeout(() => setPageIndex(3), 4000);
                  },
                },
              ]
            }
          />
        )}
        {pageIndex === 2 && (
          <div className="img-container">
            <img className="" src={gif} alt="" />
          </div>
        )}
        {pageIndex === 3 && (
          <div className="animated slideInRight">
            <Form
              title="Cálculo concluído com sucesso!"
              description="Informe seus dados abaixo para que possamos avançar"
              buttonText="Continuar"
              onFinish={async (data) => {
                props.updateUser(data);
                setPageIndex(4);

                const hubspotData = {
                  firstname: data.name,
                  email: data.email,
                  company: data.company,
                  hs_language: navigator.language.toLowerCase() || 'pt-br',
                  lifecyclestage: 'subscriber',
                  ...(props.source && props.source !== '' && { utm_source: props.source }),
                  ...(props.medium && props.medium !== '' && { utm_medium: props.medium }),
                  ...(props.campaign && props.campaign !== '' && { utm_campaign: props.campaign }),
                }

                if (process.env.NODE_ENV === 'development') return;

                axios.get(`https://api.zerobounce.net/v2/validate?api_key=${process.env.REACT_APP_ZERO_BOUNCE_KEY}&email=${hubspotData.email}&ip_address=`)
                  .then((res) => {
                    const status = res.data.status;
                    const substatus = res.data.sub_status;
                    const isInvalid =  status === 'invalid' || status === 'abuse' || (status === 'do_not_mail' && ['disposable', 'role_based_catch_all', 'global_suppression', 'toxic'].includes(substatus));
                    const isValid = !isInvalid

                    if (status && isValid) {
                      setSendToHubspost(true);
                      const baseUrl = process.env.REACT_APP_WEBSITE_BACKEND_BASE_URL
                      if (baseUrl) {
                        axios.post(`${baseUrl}/api/hubspotCreateContact`, hubspotData, { headers: {'Access-Control-Allow-Origin': '*'} })
                          .then((res) => {
                            if (res.status !== 200) {
                              rollbar.error('Status different from 200 on hubspot creating contact', { error: res })
                            }
                          })
                          .catch((error) => rollbar.error('Error on creating hubspot contact', { error }))
                      }
                    } else {
                      setSendToHubspost(false);
                    }
                  })
              }}
              {...props}
            />
          </div>
        )}
      </Wrapper>}
      {pageIndex === 4 && (
        <div className="animated slideInRight">
          <FinishResume {...props} sendToHubspot={sendToHubspot} />
        </div>
      )}
    </>
  );
}

export default FormSection;
