import React from 'react';
import Button from '../Button/Button';
import TextWithDescription from '../TitleWithDescription/TitleWithDescription';
import { ButtonsWrapper, CallToActionWrapper, Content } from './SectionTextButtons.styles';

const SectionTextButtons = ({
  title,
  text,
  buttons,
  buttonsAlignment,
  backgroundColor,
  className,
  textAlign = 'left',
}) => {
  return (
    <CallToActionWrapper className={className} textAlign={textAlign}>
      <Content backgroundColor={backgroundColor}>
        <TextWithDescription title={title} description={text} titleVariant={1} />
        {buttons && buttons.length > 0 && (
          <ButtonsWrapper buttonsAlignment={buttonsAlignment}>
            <div>
              {buttons.map((button, index) => (
                <Button
                  key={index}
                  variant={button.type}
                  backgroundColor={backgroundColor}
                  onClick={button.onClick}
                >
                  {button.title}
                </Button>
              ))}
            </div>
          </ButtonsWrapper>
        )}
      </Content>
    </CallToActionWrapper>
  );
};

export default SectionTextButtons;
