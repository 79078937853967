import styled from "styled-components";
import media from "../../styles/media";

export const Wrapper = styled.div`
  width: 100%;

  ul {
    padding: 0px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 26px;

    li {
      list-style: none;
      background-color: white;
      padding: 10px 20px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      gap: 14px;
      flex-shrink: 0;
      cursor: pointer;
      transition: all 0.1s;

      :hover {
        background-color: ${(props) => props.theme.colors.lightGrayBlue};

        div.check {
          background-color: white;
        }
      }

      div.check {
        min-width: 30px;
        min-height: 30px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${(props) => props.theme.colors.lightGrayBlue};

        svg {
          color: ${(props) => props.theme.colors.boldText};
        }
      }
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  ${media('mediumPlus', true)} {
    display: flex;
    flex-direction: column;
    height: 100%;

    ul {
      /* flex: 1; */
      overflow-y: auto;
    }

    footer {
      margin-top: 10px;
    }
  }
`;