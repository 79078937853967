import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import AnimatedNumbers from "react-animated-numbers";

import ConfirmRequest from '../confirmRequest';
import { Wrapper } from './FinishResume.styles';
import { getTotalHours, getTotalPrice } from '../../utils/calculations'
import Button from '../Button/Button';
import Input from '../Input/Input';
import * as gtag from '../../utils/gtag';

import Rollbar from 'rollbar'
import { submitHubSpotForm } from '../../utils/hubspot';

const rollbar = new Rollbar({
  environment: process.env.NODE_ENV,
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
})

const formURL = process.env.REACT_APP_FORM_URL;



class Resume extends Component {
  constructor() {
    super();

    this.address = {};
    this.wrapperRef = React.createRef();

    this.numberToReal = this.numberToReal.bind(this);
    this.calculateDeadline = this.calculateDeadline.bind(this);
    this.showValues = this.showValues.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.handleResume = this.compactResume.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.calculateBudgetAndDeadline = this.calculateBudgetAndDeadline.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);

    this.state = {
      formFinished: false,
      price: '',
      deadline: 0,
      form: {
        priority: 'quality',
        mvp: 'mvp',
        lookingFor: '',
      },
      timesChanged: 0,
      showPhone: false,
      phone: '',
      canShake: true,
      sendToHubspotOnce: false,
    };
  }


  componentDidMount() {
    this.calculateBudgetAndDeadline();
    this.getLocation();
    window.fbq('track', 'Search');

   
  }

  calculateBudgetAndDeadline() {
    // Hours and price multiplier for each one of the priority options
    const hoursMultiplier = {
      quality: 1,
      budget: 2,
      deadline: 0.8,
    }
    const priceMultiplier = {
      quality: 1,
      budget: 0.9,
      deadline: 1.4,
    }
    
    // Calculating time and price for the selected options
    const hours = getTotalHours(this.props.state.resume); // Don't multiply here, or the calculations will be wrong
    const deadline = this.calculateDeadline(hours * hoursMultiplier[this.state.form.priority]);
    const price = this.numberToReal(getTotalPrice(hours) * priceMultiplier[this.state.form.priority]);
    this.setState({ price, deadline })

    const {
      name, email, alert, company, referer, source, medium, campaign,
    } = this.props;
    rollbar.info('New lead', { lead: { name, email, alert, company, referer, source, medium, campaign, price, deadline } });
  }

  getLocation() {
    axios
      .get(
        'https://api.ipinfodb.com/v3/ip-city/?key=08795bcac02db57a653f53432f1c73f257d1ce2826c90811589aea481b2f9ef8&format=json',
      )
      .then((resp) => {
        this.address.ip = resp.data.ipAddress;
        this.address.city = resp.data.cityName;
        this.address.stateProv = resp.data.regionName;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  numberToReal(value) {
    return value.toFixed(0);
  }

  calculateDeadline(value) {
    const deadline = Math.ceil(value / 8 / 5); // 8 Hours/Day - 5 Days/Week
    return deadline;
  }

  showValues() {
    this.setState({
      display: false,
      open: true,
    });

    setTimeout(
      () => {
        this.setState({ open: false });
      },
      3000,
    );
  }


  compactResume() {
    return this.props.state.resume
      .map((item) => `${item.category}: ${item.option.label}`)
      .reduce((prevVal, item) => `${prevVal}<br/>${item}`);
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  handlePhoneChange(value) {
    const x = value
      .replace(/\D/g, "")
      .match(/(\d{0,2})(\d{0,5})(\d{0,4})/);

    value = !x[2]
      ? x[1]
      : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");

    this.setState({
      phone: value,
    });
}

  generateEmailToUs() {
    // const {
    //   name,
    //   email,
    //   alert,
    //   company,
    //   referer,
    //   source,
    //   medium,
    //   resume,
    //   campaign,
    // } = this.props;
    
    // const {priority, mvp, lookingFor} = this.state.form;

    // const body = {
    //   name,
    //   email,
    //   alert,
    //   company,
    //   referer,
    //   source,
    //   medium,
    //   campaign,
    //   resume,
    //   price: this.state.price,
    //   deadline: this.state.deadline + ' semanas',
    //   proposalRequested: 'Não',
    //   city: this.address.city,
    //   stateProv: this.address.stateProv,
    //   phone: '',
    //   talk: '',
    //   message: '',
    //   sumHour: getTotalHours(this.props.state.resume),
    //   priority,
    //   mvp,
    //   lookingFor,
    //   timesChanged: this.state.timesChanged,
    // }
    // if (this.props.mock?.mock && process.env.NODE_ENV === 'development') {
    //   console.log('Would send an email to US');
    //   return;
    // }
    // sendEmailUs(body);
  }

  addToSpreadsheet(postToHubspot) {
    const {
      name, email, alert, company, referer, source, medium, campaign,
    } = this.props;
    const {priority, mvp, lookingFor} = this.state.form;
    const data = {
      name,
      email,
      alert,
      company,
      interest: '' || this.state.interest,
      talk: '',
      phone: '',
      ip: this.address.ip,
      city: this.address.city,
      state: this.address.stateProv,
      phone: this.state.phone,
      referer,
      source,
      medium,
      campaign,
      message: '',
      price: this.state.price,
      deadline: this.state.deadline + ' semanas',
      obs: '',
      status: '',
      itens: this.compactResume().split('<br/>').join('\n'),
      priority,
      mvp,
      lookingFor,
      timesChanged: this.state.timesChanged,
    };

    if (this.props.mock?.mock && process.env.NODE_ENV === 'development') {
      console.log('Would save it on the spreadsheet');
      return;
    }

    rollbar.info('Lead interested', { lead: data });

    if (this.props.sendToHubspot && postToHubspot) {
      if (data.phone) {
        // If user entered a phone we should update their contact on hubspot
        const baseUrl = process.env.REACT_APP_WEBSITE_BACKEND_BASE_URL
        if (baseUrl) {
          axios.post(`${baseUrl}/api/hubspotCreateContact`, 
            { 
              phone: data.phone, firstname: name,
              email,
              company, 
            }, { headers: {'Access-Control-Allow-Origin': '*'} })
            .then((res) => {
              if (res.status !== 200) {
                rollbar.error('Status different from 200 on hubspot updating contact with phone', { error: res })
              }
            })
            .catch((error) => rollbar.error('Error on updating hubspot contact with phone', { error }))
        }
      } else {
        const hubspotData = {
          firstname: name,
          email,
          company,
          hs_language: navigator.language.toLowerCase() || 'pt-br',
          ...(source && source !== '' && { utm_source: source }),
          ...(medium && medium !== '' && { utm_medium: medium }),
          ...(campaign && campaign !== '' && { utm_campaign: campaign }),
        }
        if (!this.state.sendToHubspotOnce) {
          this.setState({ sendToHubspotOnce: true })
          submitHubSpotForm(process.env.REACT_APP_HUBSPOT_FORM_ID, process.env.REACT_APP_HUBSPOT_PORTAL_ID, hubspotData)
          .then(async (res) => {
            const data = await res.json();
            if (data.status === 'error') {
              rollbar.error('Error on submiting hubspot form', { error: data })
            }
          })
          .catch((error) => {
            rollbar.error('Error on submiting hubspot form', { error })
          })

        }
      }
      
    }

    axios
      .post(formURL, qs.stringify(data), {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        console.log('Sent to spreadsheet', response);
      })
      .catch((error) => {
        console.log('Error envio data', error.response);
      });
  }
  onChange(event) {
    // Changing value of the form on the state
    this.setState({ form: {...this.state.form, [event.target.name]: event.target.value }}, () => {
      // Counting how many times the priority changed
      if (event.target.name === 'priority') {
        this.setState({ timesChanged: this.state.timesChanged + 1 });

        // TODO: Scroll to the element showing the values

        // Recalculating values of estimations
        this.calculateBudgetAndDeadline();

        if (event.target.value === 'budget') {
          this.setState({ canShake: false })
        }
      }

      if (event.target.name === 'lookingFor') {
        this.setState({ canShake: false })

        // Post it to the Google Sheet 
        if (event.target.value === 'yes') {
          gtag.event('conversion');
        }
        this.addToSpreadsheet(['yes', 'partially'].includes(event.target.value));

        // Checking if the result is positive
        if (['yes', 'partially'].includes(event.target.value)) {
          this.generateEmailToUs();
        } else {
          this.setState({ showPhone: false })
        }
      }
    });    
  }
  

  render() {
    const { formFinished } = this.state;
    const showMVPSection = false;

    return (
      <Wrapper>
        <div>
          {!formFinished ? 
            (
              <div className="result-wrapper">
                <p>Pensando em um <b>MVP</b> do seu projeto, estimamos que seu os seguintes custo e prazos</p>
                <div className="total-resume">
                  <div className="box-total">
                    <span className="sum-price"><AnimatedNumbers animateToNumber={this.state.deadline} /> <small>{this.state.deadline === 1 ? 'Semana' : 'Semanas'}</small></span>
                  </div>
                  <div className="box-total">
                    <span className="sum-price"><small>R$ </small><AnimatedNumbers locale="pt-BR" includeComma animateToNumber={this.state.price} /></span>
                  </div>
                </div>
                <div>
                  <h2 className="section-title">Qual sua prioridade?</h2>
                  <p>Vamos recalcular o preço e prazo do seu <b>MVP</b> de acordo</p>
                  <div 
                    className="radio-group" 
                    value={this.state.form.priority}  
                    onChange={(event) => this.onChange(event)}
                    >                     
                    <label htmlFor="quality">
                      <input type="radio" id="quality" name="priority" value="quality" defaultChecked />
                      <span className="label-title">Qualidade</span>
                    </label>                
                    <label htmlFor="deadline">
                      <input type="radio" id="deadline" name="priority" value="deadline" />
                      <span className="label-title">Prazo</span>
                    </label>                
                    <label className={this.state.form.priority !== 'budget' && this.state.canShake ? 'shake' : ''} htmlFor="budget">
                      <input type="radio" id="budget" name="priority" value="budget" />
                      <span className="label-title">Preço</span>
                    </label>    
                  </div>
                  {showMVPSection && <>
                    <h2 className="section-title">Buscando um MVP?</h2>                  
                    <p>{`Um "Produto Mínimo Viável" (MVP) é uma versão reduzida do projeto, focado apenas na solução mais importante`}</p>
                    <div 
                      className="radio-group mvp" 
                      value={this.state.form.mvp}  
                      onChange={(event) => this.onChange(event)}
                      >                   
                      <label htmlFor="mvp">
                        <input type="radio" id="mvp" name="mvp" value="mvp" defaultChecked/>
                        <div>
                          <span className="label-title">MVP</span>
                          <p className="label-detail">Ideal para quem quer construir uma versão mais simples e enxuta de um produto, empregando o mínimo possível de recursos para entregar a principal proposta de valor da ideia.</p>
                        </div>
                      </label>
                      <label htmlFor="complete">
                        <input type="radio" id="complete" name="mvp" value="complete" />
                        <span className="label-title">Projeto completo</span>
                      </label>
                    </div>
                  </>}

                  <h2 className="section-title">
                    O orçamento está dentro do que você procura?
                  </h2>
                  <p>Os valores são aproximados, mas próximos do que você imaginou?</p>
                  <div 
                    className="radio-group" 
                    value={this.state.form.lookingFor} 
                    onChange={(event) => this.onChange(event)}
                  >                   
                    <label htmlFor="yes">
                      <input type="radio" id="yes" name="lookingFor" value="yes"/> 
                      <span className="label-title">Sim</span>
                    </label>
                    
                    <label htmlFor="partially">
                      <input type="radio" id="partially" name="lookingFor" value="partially" />
                      <span className="label-title">Parcialmente</span>
                    </label>
                  
                    <label htmlFor="no">
                      <input type="radio" id="no" name="lookingFor" value="no" />
                      <span className="label-title">Fora</span>
                    </label>
                  </div>
                  
                  {this.state.form.lookingFor && 
                    <>
                      {['yes', 'partially'].includes(this.state.form.lookingFor) ? 
                        <div className={`button-wrapper ${!this.state.showPhone ? 'shake' : ''}`}>
                          <Button onClick={() => this.setState({ showPhone: true })}>
                            Receber contato para mais informações
                          </Button>
                        </div>  
                      : <></>
                      } 
                    </>
                  }
                  {this.state.showPhone && (
                    <>
                      <label htmlFor='phone' className='phone-label'>
                        <span>Informe o telefone de contato</span>
                        <Input
                          autoFocus
                          id="phone"
                          type="text"
                          value={this.state.phone}
                          placeholder="(xx) xxxxx-xxxx"
                          onChange={(e) => this.handlePhoneChange(e.target.value)}
                        />
                      </label>
                    </>
                  )}
                  {this.state.form.lookingFor && (
                    <Button 
                      onClick={() => {
                        if (['yes', 'partially'].includes(this.state.form.lookingFor)) {
                          this.addToSpreadsheet(true);
                        }
                        this.setState({ formFinished: true })
                      }}
                      style={{ width: '100%', marginBottom: '30px' }}
                      disabled={(['yes', 'partially'].includes(this.state.form.lookingFor) && this.state.phone.length !== 15)}
                    >
                      Concluir
                    </Button>
                  )}
                  <div className="info-wrapper">    
                    <small>
                      As informações apresentadas não constituem uma proposta final.
                      Precisaremos conhecer em detalhes sua demanda para chegarmos ao orçamento exato.
                      Nosso objetivo aqui é lhe apresentar uma noção de prazos e custos.
                    </small>
                  </div>
                </div>
              </div>
            
          ) : <ConfirmRequest
              state={this.props.state}
              price={this.state.price}
              deadline={this.state.deadline}
              address={this.address}
              interest={this.state.interest}
              handleResume={this.compactResume}
              lookingFor={this.state.form.lookingFor}
              resetToFirstView={this.props.resetToFirstView}
            />
            }
         
        </div>
      </Wrapper>
    );
  }
}

export default withRouter(Resume);
