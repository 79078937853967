import React from 'react';
import Button from '../Button/Button';
import TextWithDescription from '../TitleWithDescription/TitleWithDescription';
import { Wrapper } from './Questions.styles';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { BiCheck } from 'react-icons/bi';
import { useMedia } from 'react-use';

const Questions = ({ questions, currentQuestion, setCurrentQuestion, setSelectedAnswer }) => {
  const question = questions[currentQuestion]
  const selectedAnswer = question.selectedAnswer;

  const slideDirection = React.useRef("slideInRight") ;
  const isMobile = useMedia("(max-width: 940px)")

  return (
    <Wrapper key={currentQuestion}>
      <div className={`animated ${slideDirection.current}`}>
        <TextWithDescription 
          title={question.title} 
          titleVariant={1} 
          description={question.subtitle} 
        />
        <ul>
          {question.options.map((opt) => (
            <li key={opt.label} onClick={() => {
              setSelectedAnswer(opt);
              setCurrentQuestion(currentQuestion + 1);
              slideDirection.current = "slideInRight"
            }}>
              <div className="check">
                {selectedAnswer?.label === opt.label && (
                  <BiCheck size={24} />
                )}
              </div>
              {`${opt.label[0].toUpperCase()}${opt.label.slice(1).toLowerCase()}`}
            </li>
          ))}
        </ul>
      </div>
      <footer style={currentQuestion === 0 ? { justifyContent: 'flex-end' } : {}}>
        {currentQuestion > 0 && (
          <Button 
            variant="ghost" 
            onClick={() => {
              setCurrentQuestion(currentQuestion - 1);
              slideDirection.current = isMobile ? "slideInLeft" : "fadeIn";
            }}>
            <BsChevronLeft size={18} /> Anterior
          </Button>
        )}
      </footer>
    </Wrapper>
  )
};

export default Questions;